import classnames from 'classnames'
import Badge from 'components/Badge'

import styles from './FreeShippingOverBadge.module.css'

type FreeShippingOverBadgeProps = {
  className?: string
  currencySymbol?: string | null
  freeShippingOver: number | undefined | null
}

const FreeShippingOverBadge = ({ className, freeShippingOver, currencySymbol = '$' }: FreeShippingOverBadgeProps) => {
  return (
    <>
      {freeShippingOver != null && (
        <div className={classnames(styles.freeShippingOverContainer, className)}>
          <Badge
            className={styles.freeShippingBadge}
            style="tinted"
            leadingIcon="truck"
            leadingIconColor="var(--colorGreen8)">
            Free Shipping{' '}
            {freeShippingOver > 0
              ? `over ${currencySymbol}${Math.round(freeShippingOver).toLocaleString('en-US')}`
              : null}
          </Badge>
        </div>
      )}
    </>
  )
}

export default FreeShippingOverBadge
