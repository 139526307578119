import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import Link from 'next/link'
import classnames from 'classnames'
import upperFirst from 'lodash.upperfirst'

import { useUserSession } from 'hooks'
import UserContentImage from 'components/UserContentImage'
import CatalogCurrencyIcon from 'components/Icon/CatalogCurrency'
import PaymentTermsOfferBadge from '../PaymentTermsOfferBadge'
import FreeShippingOverBadge from '../FreeShippingOverBadge'
import MinimumTotalOrderBadge from '../MinimumTotalOrderBadge'
import { PromotionsPreviewMode } from 'modules/seller-hub/promotions/components/PromotionConfigureForm/PromotionConfigureForm'
import { GetCatalogsForShopPage_marketplaceCatalogs_nodes } from '../../pages/CatalogListPage/graphql/__generated__/GetCatalogsForShopPage'
import {
  GetDepartments,
  GetDepartments_departmentFullList_departmentChildren_child
} from 'modules/marketplace/common/graphql/__generated__/GetDepartments'

import GET_DEPARTMENTS from 'modules/marketplace/common/graphql/GetDepartments.graphql'
import GET_BUYER_PRICING_REQUEST_DATA from './graphql/GetBuyerPricingRequestData.graphql'
import GET_REP_PRICING_REQUEST_DATA from './graphql/GetRepPricingRequestData.graphql'
import { GetBuyerPricingRequestData } from './graphql/__generated__/GetBuyerPricingRequestData'
import { GetRepPricingRequestData } from './graphql/__generated__/GetRepPricingRequestData'
import { PricingRequestStatusEnum, RepPricingRequestStatusEnum } from '../../../../../../__generated__/globalTypes'
// TODO: completely remove ICatalog
import { ICatalog } from 'types/Catalog'

import styles from './CatalogListItem.module.css'

const DepartmentListItem = ({
  department
}: {
  department: GetDepartments_departmentFullList_departmentChildren_child
}) => {
  const departmentUrl = `/shop/d/${department.slugPath.join('/')}`

  return (
    <li className={styles.departmentListItem}>
      <Link href={departmentUrl}>
        <a>{department.name}</a>
      </Link>
    </li>
  )
}

type CatalogListItemProps = {
  catalog: GetCatalogsForShopPage_marketplaceCatalogs_nodes | ICatalog
  className?: string
  isPromotion?: boolean
  isPreview?: boolean // controls whether we will display draft products
  promotionsPreviewMode?: PromotionsPreviewMode // Only for retailer-email catalog cover. Doesn't apply for other promotion types
  showDepartmentLinks?: boolean
}

const CatalogListItem = ({
  catalog,
  className,
  isPromotion = false,
  isPreview = false,
  promotionsPreviewMode,
  showDepartmentLinks = true
}: CatalogListItemProps) => {
  const [hasPricing, setHasPricing] = useState(false)
  const { isGuest, isRep, isBuyer } = useUserSession()

  const sellerSlug = catalog.seller?.slug
  const sellerName = catalog.seller?.displayName
  const currencySymbol = catalog.currencySymbol
  const freeShippingOver = catalog.freeShippingOver
  const paymentTermsOffer = catalog.paymentTermsOffer
  const minimumTotalOrderValue = catalog.minimumTotalOrderValue

  /* NOTES:
   *
   * Here we fetch _all_ pricing requests for the current Buyer or Rep.
   *
   * Since <CatalogListItem may be rendered in a loop, this should only make the
   * request the first time, and pull from the Apollo cache in subsequent
   * requests
   */
  const [getBuyerPricingRequestData] = useLazyQuery<GetBuyerPricingRequestData>(GET_BUYER_PRICING_REQUEST_DATA)
  const [getRepPricingRequestData] = useLazyQuery<GetRepPricingRequestData>(GET_REP_PRICING_REQUEST_DATA)

  useEffect(() => {
    const fetchPriceRequestData = async () => {
      if (isBuyer) {
        const requestStatus = (await getBuyerPricingRequestData()).data?.currentBuyer.pricingRequests.nodes.find(
          req => req.sellerId === catalog.id.toString()
        )?.status
        requestStatus &&
          setHasPricing(PricingRequestStatusEnum[requestStatus] === PricingRequestStatusEnum.APPROVED ? true : false)
      }

      if (isRep) {
        const requestStatus = (await getRepPricingRequestData()).data?.currentRep.pricingRequests.nodes.find(
          req => req.sellerId === catalog.id.toString()
        )?.status
        requestStatus &&
          setHasPricing(
            RepPricingRequestStatusEnum[requestStatus] === RepPricingRequestStatusEnum.APPROVED ? true : false
          )
      }
    }
    fetchPriceRequestData()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [catalog.id, isBuyer, isRep])

  // GET_DEPARTMENTS should be cached, so safe to fetch in loop
  const departmentsQuery = useQuery<GetDepartments>(GET_DEPARTMENTS, { skip: !showDepartmentLinks })
  const allDepartments = departmentsQuery.data?.departmentFullList
  const departments = allDepartments?.reduce((childDepartments, superDept) => {
    const matches = superDept.departmentChildren
      .filter(c => {
        return c.child != null && catalog.departmentIds?.includes(c.child.id)
      })
      .map(c => c.child) as GetDepartments_departmentFullList_departmentChildren_child[]

    return [...new Set(childDepartments.concat(matches))]
  }, [] as GetDepartments_departmentFullList_departmentChildren_child[])

  return (
    <div
      className={classnames(
        className,
        styles.catalogListItem,
        promotionsPreviewMode ? styles.isPreview : {},
        {
          [styles[`isPreview${upperFirst(promotionsPreviewMode)}`]]: promotionsPreviewMode
        },
        { [styles.isPromotion]: isPromotion }
      )}>
      <Link href="/[seller]/[catalog]" as={`/${sellerSlug}/${catalog.slug}${isPreview ? '?preview=1' : ''}`}>
        <a>
          <div className={styles.catalogCover}>
            <UserContentImage src={catalog.photoMediumUrl ?? ''} alt={`${sellerName} - ${catalog.name}`} />
          </div>
          <h4 className={styles.catalogName}>{catalog.name}</h4>
        </a>
      </Link>

      <Link href={`/${sellerSlug}`}>
        <a className={styles.sellerName}>{sellerName}</a>
      </Link>

      {/* TODO: this is currently hidden in CSS, reenable once designer has added to the design */}
      {!isGuest && (
        <div className={styles.catalogCurrencyContainer}>
          <span className={styles.catalogCurrencyCode}>{catalog.currencyCode}</span>
          {hasPricing && <CatalogCurrencyIcon />}
        </div>
      )}

      {showDepartmentLinks && (
        <ul className={styles.departmentsList}>
          {departments?.map((department, index) => (
            <DepartmentListItem department={department} key={index} />
          ))}
        </ul>
      )}

      <MinimumTotalOrderBadge currencySymbol={currencySymbol} minimumTotalOrderValue={minimumTotalOrderValue} />
      <FreeShippingOverBadge currencySymbol={currencySymbol} freeShippingOver={freeShippingOver} />
      <PaymentTermsOfferBadge paymentTermsOffer={paymentTermsOffer} />
    </div>
  )
}

export default CatalogListItem
